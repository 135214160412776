import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Task from "./Task";
import TaskForm from "./TaskForm";
import axios from "axios";
import { URL } from "../App";
//http://localhost:5000/api/tasks

const TaskList = () => {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [completedTasks, setCompletedTasks]=useState([])
  const [formData, setFormData] = useState({
    name: "",
    completed: false,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [taskID, setTaskID] = useState("");

  const { name } = formData;

  const url = `${URL}/api/tasks`;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const getTasks = async () => {
    setIsLoading(true);
    try { 
      const { data } = await axios.get(url);
      setTasks(data);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  const deleteTask = async (id) => {
    try {
      await axios.delete(`${url}/${id}`);
      getTasks();
    } catch (error) {
      toast.error(error.message);
    }
  };

 useEffect(()=>{
    const cTask = tasks.filter((task)=>{
      return task.completed === true
    })
    setCompletedTasks(cTask)

 },[tasks])

  useEffect(() => {
    getTasks();
  }, []);

  const GetSingleTask = async (task) => {
    setFormData({
      name: task.name,
      completed: false,
    });
    setTaskID(task._id)
    setIsEditing(true)
  };

 const updateTask = async(e)=>{
  e.preventDefault()
  if(name === ""){
    return toast.error("input field can not be empty")
  }
  try {
    await axios.put(`${url}/${taskID}`, formData)
    setFormData({...formData, name:""})
    setIsEditing(false)
    getTasks()
    console.log(formData)
  } catch (error) {
    toast.error(error.message)
  }

 }

 const setToComplete = async(task)=>{
     const newFormData = {
          name: task.name,
          completed: true,

     }
     try {
      await axios.put(`${url}/${task._id}`, newFormData)
      getTasks()

     } catch (error) {
      toast.error(error.message)
     }

 }

  const createTask = async (e) => {
    e.preventDefault();
    if (name === "") {
      return toast.error("This field can not be empty");
    }
    try {
      await axios.post(url, formData);
      setFormData({ ...formData, name: "" });
      getTasks()
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  return (
    <div>
      <h1>Task Manager</h1>
      <TaskForm
        name={name}
        handleInputChange={handleInputChange}
        createTask={createTask}
        isEditing={isEditing}
      />
      {tasks.length > 0 && (
            <div className="--flex-between --pb">
            <p>
              <b>total tasks:</b> {tasks.length}
            </p>
            <p>
              <b>completed tasks:</b> {completedTasks.length}
            </p>
          </div>
      )}
      <hr></hr>
      {isLoading && <div className="--flex-center"></div>}
      {!isLoading && tasks.length === 0 ? (
        <p className="--py">no task aded, please add a new task</p>
      ) : (
        <>
          {tasks.map((task, index) => {
            return (
              <Task
                key={task._id}
                task={task}
                index={index}
                deleteTask={deleteTask}
                getSingleTask={GetSingleTask}
                updateTask={updateTask}
                setToComplete={setToComplete}
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default TaskList;
